<template>
	<div class="px-2 sm:px-8 py-6 mt-4">
		<Header v-bind="content.profile" isTitle @onClick="() => isOpenArchive = true" title="Venture Settings" />
		<div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
			<div class="col-span-2">
				<div class="flex justify-between items-center">
					<Text size="xl" weight="semibold" color="gray-900" :content="'Venture Info'" />
					<Icon name="InformationCircleIcon" size="6" color="gray-400" />
				</div>
				<Text @click="isOpen = !isOpen" size="sm" color="primary-600" :content="isOpen ? 'hide' : 'show'"
					decoration="underline" custom-class="cursor-pointer mt-2" />
				<div class="animate__animated" :class="isOpen ? 'animate__fadeIn' : 'animate__fadeOut'">
					<p class="leading-4 mt-4">
						<Text size="xs" color="black" weight="bold" content="Name: " element="span" />
						<Text size="xs" color="black" weight="base"
							:content="`An easy way to reference your idea, even if it's not the &#8221official&#8221 name`"
							element="span" />
					</p>
					<p class="leading-4 mt-3">
						<Text size="xs" color="black" weight="bold" content="Idea: " element="span" />
						<Text size="xs" color="black" weight="base"
							:content="`A brief description of what you're thinking about creating.`" element="span" />
					</p>
				</div>

			</div>
			<div class="col-span-5">
				<div class="shadow-lg h-fit rounded-md">
					<div
						class="px-5 py-8 grid grid-cols-1 gap-y-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
						<div v-for="key in Object.keys(content.info)" :key="key" class="w-full">
							<Input v-if="content.info[key].type === 'text'" :label="content.info[key].label"
								v-model:value="content.info[key].value" :type="content.info[key].type" />
							<Textarea v-if="content.info[key].type === 'textarea'" :label="content.info[key].label"
								v-model:value="content.info[key].value" custom-class="w-full" />
						</div>

					</div>
					<div
						class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
						<div />
						<div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
							<Button content="Cancel" :variant="null" customClass="bg-white text-black underline px-2 bg-gray-100" />
							<Button content="Save" variant="primary" customClass="ml-4 px-4" :isLoading="isLoading" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
			<div class="col-span-2">
				<Text size="xl" weight="semibold" color="gray-900" :content="'Accelerator'" />
				<Text size="xs" color="black" weight="base"
					:content="`Change DSCVRY from a tool to a guided step-by-step accelerator process for just $99. You'll get a focused UI, clear goals, milestone-based education, and access to our private Slack channel.`"
					custom-class="mt-4 leading-5" />
			</div>
			<div class="col-span-5">
				<div class="h-fit">
					<div class="bg-white border border-gray-300 rounded-md px-5 py-8">
						<div>
							<Text size="sm" weight="semibold" color="black" content="Accelerator Mode" />
							<div class="flex items-center space-x-5 mt-4">
								<Toggle v-model:value="isAccelerate" />
								<Text size="sm" color="black" :content="isAccelerate ? 'ON' : 'OFF'" />
							</div>
							<div v-if="isAccelerate" class="mt-4">
								<Text size="sm" weight="semibold" content="Payment Method" />
								<div class="space-y-2 mt-3">
									<div v-for="payment in content.payments" :key="payment.id"
										class="flex justify-between items-center px-4 py-2 rounded-md bg-gray-200">
										<div class="flex items-center space-x-2">
											<Text size="xs" color="black" :content="`Ending in *${payment.last4}`" weight="semibold" />
										</div>
										<Text size="sm" color="primary-600" :content="'edit'" decoration="underline"
											custom-class="cursor-pointer" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
			<div class="col-span-2">
				<Text size="xl" weight="semibold" color="gray-900" :content="'Your Venture Team'" />
				<Text size="xs" color="black" weight="base"
					:content="`Invite anyone you want to help you on this journey. 'Members' are like co-founders or teammates who can add and edit information. 'Read-only' users are like mentors and advisors who can only see what you're working on, but not edit anything.`"
					custom-class="mt-4 leading-5" />
			</div>
			<div class="col-span-5">
				<div class="h-fit rounded-md">
					<div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
						<div v-for="contact in contacts" :key="contact.type" class="grid grid-cols-4 py-3 px-2">
							<div class="col-span-2">
								<Text size="sm" weight="semibold" color="black" :content="contact.value" />
							</div>
							<div class="col-span-1">
								<Badge :label="contact.type" :icon-name="contact.icon" isLeft bg-color="gray-500" is-rounded px="3" py="1"
									text-color="white" />
							</div>
							<div class="col-span-1 flex justify-end">
								<!--									<a class="px-1">-->
								<!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
								<!--									</a>-->
								<a v-if="contact?.type !== 'Owner'" @click="onOpenRemove('CONTACT', contact)" class="px-1">
									<Icon name="TrashIcon" color="red-600" class="hover:text-red-900" />
								</a>
							</div>
						</div>
					</div>
					<Text size="sm" @click="() => isShow = true" color="primary-600" content="Invite someone" decoration="underline"
						custom-class="cursor-pointer mt-5" />
				</div>
			</div>
		</div>
		<div v-if="companies?.length > 0" class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
			<div class="col-span-2">
				<Text size="xl" weight="semibold" color="gray-900" :content="'Ecosystem'" />
				<Text size="xs" color="black" weight="base"
					:content="`Any ecosystems like schools or startup incubators that you've been invited to be a part of will show up here.`"
					custom-class="mt-4 leading-5" />
			</div>
			<div class="col-span-5">
				<div class="h-fit rounded-md">
					<div v-if="companies?.length > 0" class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
						<div v-for="eco in companies" :key="eco.id" class="grid grid-cols-4 py-3 px-2">
							<div class="col-span-2">
								<Text size="sm" weight="semibold" color="black" :content="eco.name" />
							</div>
							<div class="col-span-1">
								<Text size="sm" weight="semibold" color="black"
									:content="`joined ${moment(eco.date).format('M/D/YYYY')}`" />
							</div>
							<div class="col-span-1 flex justify-end">
								<!--									<a class="px-1">-->
								<!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
								<!--									</a>-->
								<a class="px-1" @click="onOpenRemove('ECOSYSTEM')">
									<Icon name="TrashIcon" color="red-600" class="hover:text-red-900" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="cohorts?.length > 0" class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
			<div class="col-span-2">
				<Text size="xl" weight="semibold" color="gray-900" :content="'Programs and Cohorts'" />
				<Text size="xs" color="black" weight="base"
					:content="`If you've been invited to join a program inside of an ecosystem, it will show up here.`"
					custom-class="mt-4 leading-5" />
			</div>
			<div class="col-span-5">
				<div class="h-fit rounded-md">
					<div v-if="cohorts?.length > 0" class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
						<div v-for="program in cohorts" :key="program.id" class="grid grid-cols-4 py-3 px-2">
							<div class="col-span-2">
								<Text size="sm" weight="semibold" color="black" :content="program.name" />
							</div>
							<div class="col-span-1">
								<Text size="sm" weight="semibold" color="black" :content="program.description" />
							</div>
							<div class="col-span-1 flex justify-end">
								<!--									<a class="px-1">-->
								<!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
								<!--									</a>-->
								<a class="px-1" @click="onOpenRemove('PROGRAM')">
									<Icon name="TrashIcon" color="red-600" class="hover:text-red-900" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<InviteMember :is-show="isShow" @onClose="isShow = false" @onSubmit="(formGroup) => onSubmitBtn(formGroup)" v-bind="{
			...c.inviteMember,
			isShow,
			isLoading,
			list: state.group.groups
		}" />
		<DeleteModal :is-show="isOpenRemove" @onClose="() => isOpenRemove = false" v-bind="removeObj" icon="TrashIcon"
			yesBtnVariant='danger' noBtnVariant='secondary' :on-no-click="() => isOpenRemove = false"
			:on-yes-click="() => onRemove()" :isLoading="isLoading"/>
		<DeleteModal :is-show="isOpenArchive" @onClose="() => isOpenArchive = false" v-bind="{
			title: `Archive the Venture <span class='font-semibold'>${venture && venture.name}</span>?`,
			description: `Are you sure you want to archive <b>${venture && venture.name}</b>? All associated data will be removed.`,
			yesBtnText: 'Archive',
			noBtnText: 'Cancel',
		}" icon="TrashIcon" yesBtnVariant='danger' noBtnVariant='secondary' :on-no-click="() => isOpenArchive = false"
			:on-yes-click="() => onArchive()" :isLoading="isLoading" />
	</div>
</template>

<script>
import EmptyState from '../components/organisms/EmptyState/Range/Range'
import ListViewHeader from '../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton'
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from '../components/organisms/Tables/Simple/Simple.vue'
import Text from '../components/atoms/Text/Text';
import { c } from '../components/constants.js';
import InviteMember from '../components/organisms/Modals/InviteMember/InviteMember.vue'
import DeleteModal from '../components/organisms/Modals/SimpleAlert/SimpleAlert'
import Header from '../components/molecules/PageViewHeader/Profile/Profile'
import Input from '../components/molecules/Inputs/Component/Component'
import Textarea from '../components/molecules/Textareas/Simple/Simple'
import Toggle from '../components/molecules/Toggles/Simple/Simple'
import Badge from '../components/molecules/Badge/Basic/Basic'
import Icon from '../components/atoms/Icons/Icons'
import moment from "moment";
import { get } from 'lodash'
import { Settings } from "../../settings";

export default {
	components: {
		EmptyState,
		ListViewHeader,
		CardWithImage,
		CardWithBadge,
		Text,
		InviteMember,
		Table,
		DeleteModal,
		Header,
		Input,
		Textarea,
		Toggle,
		Badge,
		Icon
	},
	data() {
		return {
			content: c.venture_settings,
			isShow: false,
			isOpenRemove: false,
			isOpenArchive: false,
			isOpenTip: true,
			isOpen: true,
			isAccelerate: true,
			isLoading: false,
			get,
			c,
			venture: null,
			moment,
			removeObj: null,
			contacts: [],
			companies: [],
			cohorts: [],
			inviteLink: `https://${Settings.mainDomain}/#/register-user?v=${this.$route.params?.ventureId}`,
		}
	},
	methods: {
		async onSubmitBtn(item) {
			console.log('onSubmit ====', item);
			if (item?.length > 0) {
				const { inviteContact } = await this.actions.user.inviteContact({
					contacts: [{ email: item[0].value }],
					userId: this.state.currentUser?.id,
					extraData: {
						ventureId: this.venture?.id,
						ventureName: this.venture?.name,
						groupId: item[1]?.selectedItem?.id,
						username: this.state.currentUser?.username,
						inviteLink: this.inviteLink,
						fullName: this.state.currentUser?.firstName + ' ' + this.state.currentUser?.lastName,
					}
				});
				if (inviteContact) {
					this.isShow = false;
					setTimeout(() => this.actions.alert.showSuccess({
						message: 'You\'ve successfully sent your invitation',
						title: 'Invitation sent'
					}), 1000)
				}
			}
		},
		async onRemove() {
			if (this.isLoading) return false;
			this.isLoading = true
			if (this.removeObj.type === 'CONTACT') {
				await this.actions.user.deleteUser({
					where: { id: this.removeObj?.id }
				})
				this.contacts.splice(this.contacts?.findIndex(c => c.id === this.removeObj.id), 1)
			}
			this.isOpenRemove = false;
			this.actions.alert.showError({ title: 'User successfully removed', message: 'You\'ve successfully removed!' })
			this.isLoading = false;
		},
		async onArchive() {
			await this.actions.venture.saveVenture({
				where: { id: this.venture?.id },
				data: { isArchived: true }
			})
			this.isOpenArchive = false;
			this.actions.alert.showError({ message: 'Archived venture successfully!' });
			this.$router.push({ name: 'Idea and Ventures' })
		},
		onOpenRemove(type, item) {
			this.isOpenRemove = true;
			if (type === 'CONTACT') {
				this.removeObj = {
					title: `Remove <span class='font-semibold'>${item?.value}?</span>`,
					description: `Are you sure you want to remove them from your <b>Venture Team</b>? They will no longer have access to your venture team's data.`,
					yesBtnText: 'Delete',
					noBtnText: 'Cancel',
					id: item?.id,
					type,
				}
			} else if (type === 'ECOSYSTEM') {
				this.removeObj = {
					title: `Remove an <span class='font-semibold'>Ecosystem?</span>`,
					description: `Are you sure you want to remove your <b>Range</b>? They will no longer have access to your ecosystem's data.`,
					yesBtnText: 'Delete',
					noBtnText: 'Cancel',
					id: item?.id,
					type,
				}
			} else if (type === 'PROGRAM') {
				this.removeObj = {
					title: `Archive this <span class='font-semibold'>Program?</span>`,
					description: `Are you sure you want to delete the program <b>The Idea Factory</b>? This will remove all of the cohorts linked to it.`,
					yesBtnText: 'Archive',
					noBtnText: 'Cancel',
					id: item?.id,
					type,
				}
			}
		}
	},
	async created() {
		const ventures = await this.actions.venture.getVentures({
			where: { id: this.$route.params?.ventureId },
			getValues: true,
			query: 'ventureSettings'
		});
		console.log(ventures);
		this.venture = ventures[0];
		// this.actions.setCurrentVenture(this.venture);
		this.content.info.name.value = this.venture.name;
		this.content.info.idea.value = this.venture.description;
		this.isAccelerate = this.venture.mode === 'GUIDED'
		await this.actions.group.getGroups({where: {type: 'ROLE', venture: {id: this.venture?.id}}});
		console.log(this.state.group.groups, 'groups');
		this.venture.users?.map(c => {
			this.contacts.push({
				type: c?.id === this.venture?.createdBy?.id ? 'Owner' : c?.groups?.[1]?.name,
				value: `${c?.firstName} ${c?.lastName}`,
				id: c?.id
			})
		})

		this.venture?.company?.map(c => {
			this.companies.push({
				name: c?.name,
				date: new Date(c?.createdAt)
			},)
		})

		this.venture.cohorts?.map(c => {
			this.cohorts?.push({
				name: c?.name,
				description: c?.program?.name
			})
		})

	}
}
</script>
